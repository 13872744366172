<script>
// import axios from "redaxios";
import { getSituacionIcon } from "@/utils/index.js";
import { Doughnut } from "vue-chartjs";
import colors from "vuetify/lib/util/colors";

export default {
	extends: Doughnut,
	data() {
		return {
			situacionesEstudios: null,
		};
	},
	computed: {
		options() {
			return {
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					position: "bottom",
					labels: {
						usePointStyle: true,
					},
				},
				title: {
					display: true,
					text: `Situacion de los estudios total: ${this.situacionesEstudios.totalEstudios}`,
				},
			};
		},
	},
	async mounted() {

		let { data: datos } = await axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/resumen.php`,
		});
		datos = datos.filter((c) => c.estado != "Rechazado");

		const totalEstudios = datos.reduce((t, a) => t + Number(a.numero), 0);

		const getColor = (label) => {
			let col = getSituacionIcon(label).color;
			let finalColor = col;
			try {
				if (!/#/g.test(col))
					finalColor =
						this.$vuetify.theme.themes.light[col] || colors[col]["base"];
			} catch (e) {
				finalColor = col;
			}
			return finalColor;
		};

		let results = {
			labels: [],
			datasets: [
				{
					data: [],
					hoverBackgroundColor: [],
					backgroundColor: [],
				},
			],
		};

		datos.forEach(({ numero, estado, Situacion }, index) => {
			Situacion = Situacion || estado;
			results.labels[index] = Situacion;
			let color = getColor(Situacion);
			results.datasets[0].data[index] = numero;
			results.datasets[0].hoverBackgroundColor[index] = color;
			results.datasets[0].backgroundColor[index] = color + "a0";
		});

		this.situacionesEstudios = { totalEstudios, datos: results };
		this.renderChart(this.situacionesEstudios.datos, this.options);
		this.$emit("loaded");
	},
};
</script>

<style>
</style>